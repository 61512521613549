import * as React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";
import { FancyBackground } from "../components/fancy-background";
import icon from "../images/icon.svg";

function TitlePage(props: { scroll: number; viewportHeight: number }) {
  return (
    <div className="w-full h-full flex flex-col font-inter font-medium">
      <div className="px-4 flex-grow w-full grid place-content-center gap-12 pb-12">
        <img
          className="h-48 drop-shadow-xl mx-auto"
          src={icon}
          alt="Contextie logo."
        />
        <h1
          className="text-center lg:text-6xl text-5xl text-[#fff] leading-normal"
          style={{
            filter: "drop-shadow(5px 5px 10px rgb(0 0 0 / 0.3))",
            letterSpacing: "-0.02em",
          }}
        >
          <span className="">Contextie: An AI assistant with context.</span>{" "}
        </h1>
        {/* <p className="text-center text-[#fff] ">To make you happy :)</p> */}
        <button
          className="rounded-full bg-[#fff] mx-auto text-lg font-medium py-4 px-8  text-[#666] drop-shadow-xl align-middle flex flex-row justify-center items-center"
          onClick={() => {
            // navigate to /sign-up
            window.location.href = "/sign-up";
          }}
        >
          <span>Get started</span>
          <span className="pl-3 text-3xl pb-[3px] font-light">›</span>
        </button>
        <div className="sm:h-0 h-8"></div>
      </div>
    </div>
  );
}

// markup
const IndexPage = () => {
  const [scroll, setScroll] = React.useState(0); // multiples of viewport height!
  const [viewportHeight, setViewportHeight] = React.useState(0);
  const mainRef = React.useRef(null);
  const [state, setState] = React.useState(0);
  const [delayedState, setDelayedState] = React.useState(0);
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [link, setLink] = React.useState("");
  const nameInput = React.useRef<HTMLInputElement>(null);

  const handleScroll = React.useCallback(() => {
    setScroll(window.scrollY / viewportHeight);
  }, [viewportHeight]);

  const [privateHover, setPrivateHover] = React.useState(false);
  const [secureHover, setSecureHover] = React.useState(false);
  const [focusedHover, setFocusedHover] = React.useState(false);
  const [realHover, setRealHover] = React.useState(false);

  React.useEffect(() => {
    // if the link contains #apply, then transition to state 1 immediately
    if (window.location.hash === "#apply") {
      setState(1);
      setDelayedState(1);
    }
  });

  React.useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
      handleScroll();
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleScroll]);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  React.useEffect(() => {
    setTimeout(() => {
      setDelayedState(state);
    }, 500);
  }, [state]);

  const bgColor = React.useCallback(() => {
    console.log("hiiii");
    if (state == 0) {
      return "#f9f7f1";
    } else {
      console.log("uhh");
      return "#CB8400";
    }
  }, [state]);

  React.useEffect(() => {
    const color = bgColor();
    document.body.style.backgroundColor = color;
  }, [bgColor, state]);

  // focus nameInput when delayedState is 1
  React.useEffect(() => {
    if (delayedState == 1) {
      nameInput.current?.focus();
    }
  }, [delayedState]);

  const submit = React.useCallback(async () => {
    const extra = {
      name,
      link,
    };
    const data = {
      email,
      extra: JSON.stringify(extra),
      landing_id: "exclusive",
    };
    const dataBytes = JSON.stringify(data);
    try {
      const resp = await fetch(process.env.GATSBY_API_URL + "/waitlist", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        // mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
        },
        // redirect: "follow", // manual, *follow, error
        // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: dataBytes, // body data type must match "Content-Type" header
      });
      const respD = await resp.json();
      console.log(respD);
      if (respD.success) {
        setState(2);
      } else {
        alert(
          `Something went wrong! Please try again later. Sorry :( ${respD.error}`
        );
      }
    } catch (e) {
      alert(`Something went wrong! Please try again later. Sorry :( ${e}`);
    }
  }, [email, name, link]);

  return (
    <main
      ref={mainRef}
      className="absolute top-0 left-0 right-0 bottom-0 transition duration-1000"
      style={{
        fontFamily:
          '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
        {/* <meta name="theme-color" content="#f9f7f1"></meta> */}
        <meta name="apple-mobile-web-app-capable" content="yes"></meta>
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="white"
        ></meta>
        <title>Contextie</title>
        <meta
          name="description"
          content="Contextie: An AI assistant with context."
        />
      </Helmet>
      <FancyBackground />
      <div
        className="h-full w-full transition duration-500"
        style={{
          opacity: state == 0 ? 1 : 0,
        }}
      >
        <TitlePage
          scroll={scroll}
          viewportHeight={viewportHeight}
          apply={() => {
            setState(1);
          }}
        />
        {/* <div className="animate-revealsimple sm:block hidden">
          <div className="absolute text-xs right-[20%] bottom-[25%] ">
            <div
              className="text-[#DAD6C8] cursor-default hover:text-asbrown-200 transition duration-500"
              onMouseEnter={() => {
                setPrivateHover(true);
              }}
              onMouseLeave={() => {
                setPrivateHover(false);
              }}
            >
              Fast.
            </div>
            <div className="relative">
              <div
                className="text-asbrown-200 transition duration-500 delay-300 absolute -top-16 w-64 text-center -left-[6.8rem]"
                style={{
                  opacity: privateHover ? 1 : 0,
                }}
              >
                All data, instantly.
              </div>
            </div>
          </div>
          <div className="absolute text-xs right-[25%] top-[20%]">
            <div
              className="text-[#DAD6C8] cursor-default hover:text-asbrown-200 transition duration-500"
              onMouseEnter={() => {
                setSecureHover(true);
              }}
              onMouseLeave={() => {
                setSecureHover(false);
              }}
            >
              Observable.
            </div>
            <div className="relative">
              <div
                className="text-asbrown-200 transition duration-500 delay-300 absolute -top-12 w-64 text-center -left-28"
                style={{
                  opacity: secureHover ? 1 : 0,
                }}
              >
                You can see what's happening.
              </div>
            </div>
          </div>
          <div className="absolute text-xs left-[20%] top-[25%]">
            <div
              className="text-[#DAD6C8] cursor-default hover:text-asbrown-200 transition duration-500"
              onMouseEnter={() => {
                setRealHover(true);
              }}
              onMouseLeave={() => {
                setRealHover(false);
              }}
            >
              Powerful.
            </div>
            <div className="relative">
              <div
                className="text-asbrown-200 transition duration-500 delay-300 absolute -top-12 w-64 text-center -left-28"
                style={{
                  opacity: realHover ? 1 : 0,
                }}
              >
                Knows your data format. Can answer everything.
              </div>
            </div>
          </div>
          <div className="absolute text-xs left-[25%] bottom-[20%]">
            <div
              className="text-[#DAD6C8] cursor-default hover:text-asbrown-200 transition duration-500"
              onMouseEnter={() => {
                setFocusedHover(true);
              }}
              onMouseLeave={() => {
                setFocusedHover(false);
              }}
            >
              Adaptable.
            </div>
            <div className="relative">
              <div
                className="text-asbrown-200 transition duration-500 delay-300 absolute -top-16 w-64 text-center -left-[6.5rem]"
                style={{
                  opacity: focusedHover ? 1 : 0,
                }}
              >
                Can learn anything from the web.
              </div>
            </div>
          </div>
        </div> */}
        <Footer absolute={true} />
      </div>
      <div
        className="transition duration-500 absolute top-0 left-0 right-0 bottom-0"
        style={{
          opacity: state == 1 ? 1 : 0,
          display: state == 1 ? "block" : "none",
        }}
      >
        <button
          className="absolute top-6 right-6 text-[#FFEBBD] transition duration-500 opacity-50 hover:opacity-100 cursor-pointer text-sm"
          onClick={() => {
            setState(0);
            // remove #apply from url

            window.history.pushState(
              {},
              "",
              window.location.pathname.replace("#apply", "")
            );
          }}
        >
          ⒳
        </button>
        <div
          className="grid place-content-center w-full h-full transition duration-1000"
          style={{
            opacity: delayedState == 1 ? 1 : 0,
          }}
        >
          <div className="grid gap-8 w-96 max-w-[100vw]">
            <div className="font-['Lora'] text-xl text-[#fcf9f3] text-center">
              Apply.
            </div>
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="
          m-0
          block
          h-fit
          w-full
          max-w-screen
          caret-[#fcf9f3]
          border-0
          border-b-2
          border-[#d4b15e]
          p-2 text-center 
          placeholder-[#d4b15e] focus:border-[#ead9b8]
          transition duration-500
          focus:ring-0
          bg-transparent
          text-[#fcf9f3]
          text-sm
          "
              autoFocus={delayedState == 1}
              placeholder={"George Washington"}
              ref={nameInput}
            />
            <input
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className="
          m-0
          block
          h-fit
          w-full
          border-0
          caret-[#fcf9f3]
          border-b-2
          border-[#d4b15e]
          p-2 text-center 
          placeholder-[#d4b15e] focus:border-[#ead9b8]
          transition duration-500
          focus:ring-0
          bg-transparent
          text-[#fcf9f3]
          text-sm
          "
              autoFocus={delayedState == 1}
              placeholder={"george.washington@whitehouse.gov"}
            />
            <input
              type="text"
              value={link}
              onChange={(e) => {
                setLink(e.target.value);
              }}
              className="
          m-0
          block
          h-fit
          w-full
          border-0
          border-b-2
          border-[#d4b15e]
          caret-[#fcf9f3]
          p-2 text-center 
          placeholder-[#d4b15e] focus:border-[#ead9b8]
          transition duration-500
          focus:ring-0
          bg-transparent
          text-[#fcf9f3]
          text-sm
          "
              placeholder={"https://en.wikipedia.org/wiki/George_Washington"}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  submit();
                }
              }}
            />
            <button
              className="text-[#fcf9f3] text-sm transition duration-500 hover:opacity-50 py-2 px-4 w-fit mx-auto"
              onClick={() => {
                submit();
              }}
            >
              Submit.
            </button>
          </div>
        </div>
      </div>
      <div
        className="transition duration-500 absolute top-0 left-0 right-0 bottom-0"
        style={{
          opacity: state == 2 ? 1 : 0,
          display: state == 2 ? "block" : "none",
        }}
      >
        <button
          className="absolute top-6 right-6 px-2 py-2 text-[#FFEBBD] transition duration-500 opacity-50 hover:opacity-100 cursor-pointer text-sm"
          onClick={() => {
            setState(0);
          }}
        >
          ⒳
        </button>
        <div
          className="grid place-content-center w-full h-full transition duration-1000"
          style={{
            opacity: delayedState == 2 ? 1 : 0,
          }}
        >
          <div className="grid gap-6">
            <div className="font-['Lora'] text-xl text-center text-[#fcf9f3]">
              Thank you.
            </div>
            <div className="text-center text-xs text-[#fcf9f3]">
              We will get back to you soon.
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default IndexPage;
